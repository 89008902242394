.bodyTable{
    width: 1000px;
    overflow: scroll;
}
.headerTable{
    background: #D9D9D975;
    border-bottom: 0.5px solid #5C5C5C7D;
}
.headerTable td{
    min-width: 100px;
    padding:14px 0 29px 0;
    text-align: center;
    color: #5C5C5CCF;
}
.headerTable td:first-child{
    padding-left:13px;
}
.headerTable td:last-child{
    padding-right:13px;
}
.PackageName{
    background: #0174BE0F;
    border-bottom: 0.5px solid #5C5C5C4D;
}
.PackageName td{
    padding:7px 0 7px 13px
}
.valueStatusTable{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    vertical-align: middle;
    color:#5C5C5CCF;
    text-align: center;
}
.valueStatusTable td{
    padding:20px 0 7px 0;
    border-bottom: 0.5px solid #5C5C5C4D;
}
.valueStatusTable td:first-child{
    text-align: left;
    padding-left: 13px;
}